import { LitElement, html, css } from "lit"
import Chart from 'chart.js/auto'
import Papa from 'papaparse';
import { ref, createRef } from 'lit/directives/ref.js';
import '@shoelace-style/shoelace';

export class GraphComponentElement extends LitElement {
  static styles = css`
    :host {
      display: block;
      padding: 20px;
    }
    #container {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 4fr;
      column-gap: 32px;
    }
    #controls {
      height: 100%;
    } 
    #graph-container {
      display: flex;
      flex-direction: column;
    }
    .input-card {
      margin-bottom: 8px;
    }
  `
  static properties = {
    groupSize: {state: true},
    data: {state: true}
  }

  constructor() {
    super();
    this.groups = [{ endSize: null, amount: 1 }]
    this.data = [];
  }
  
  chartRef = createRef();
  chart = null;

  updated() {
    if (!this.data || this.data.length < 1) return;
    if (this.chart) { this.chart.destroy(); }
    this.chart = new Chart(this.chartRef.value, {
      type: 'bar',
      data: {
        datasets: [{
          data: this.groupData().map((entry) => ({y: entry.data.length, x: entry.label})),
          label: "# Teachers in Case Load Range"
        }]
      }
    });
  }

  #processFile(e){
    let file = e.target.files[0]
    Papa.parse(file, {complete: (result) => {
      this.data = result.data.flat().map((str) => parseInt(str)).filter((elt) => typeof(elt) === 'number' && !isNaN(elt)).sort();
    }})
  }

  budgetCardTemplate(group, idx) {
    let groupedData = this.groupData()
    return html`
      <sl-card class="input-card">
        <div slot="header">Group ${idx + 1}</div>
        <sl-input 
          label="Smallest Case Load" min=1 value=${idx === 0 ? 0 : this.groups[idx-1].endSize + 1} type="number" pill disabled></sl-input>
        <sl-input 
          label="Largest Case Load"
          min=${idx === 0 ? 1 : this.groups[idx - 1].endSize + 1}
          type="number"
          value=${group.endSize}
          @sl-change=${(e) => {
            group.endSize = parseInt(e.target.value)
            this.requestUpdate();
          }}
          pill></sl-input>
        <sl-input
          label="Amount per Case"
          min=1
          type="number"
          value=${group.amount}
          @sl-change=${(e) => {
            group.amount = parseInt(e.target.value)
            this.requestUpdate();
          }}
          pill><span slot="prefix">$</span></sl-input>
        ${ (idx < groupedData.length) && (groupedData[idx].data.length > 0) ? html`
        <div slot="footer">
          <p># Teachers in Group: ${groupedData[idx].data.length}</p>
          <p>Required Budget for Group: $${groupedData[idx].data.reduce((acc, val) => (acc + val), 0) * group.amount}</p>
          <p># Cases in Group: ${groupedData[idx].data.reduce((acc, val) => (acc + val), 0)}</p>
        </div>
        ` : html``}
      </sl-card>
    `
  }

  groupData(data = this.data) {
    let val =  data.sort().reduce((acc, val) => {
      let maxVal = this.groups[acc.length - 1].endSize;
      while (val > maxVal && acc.length < this.groups.length) {
        acc.push({data: [], label: `${maxVal + 1} - ${this.groups[acc.length].endSize ? this.groups[acc.length].endSize : "MAX"}`, amount: this.groups[acc.length].amount})
        maxVal = this.groups[acc.length - 1].endSize;
      }
      acc[acc.length - 1].data.push(val);
      return acc;
    }, [{data: [], label: `0 - ${this.groups[0].endSize ? this.groups[0].endSize : "MAX"}`, amount: this.groups[0].amount}]);
    return val;
  }

  addGroup() {
    if (!this.groups.at(-1).endSize) {
      if (this.groups.length === 1) {
        this.groups[0].endSize = 1
      } else {
        this.groups = this.groups.with(-1,
          {
            endSize: this.groups.at(-2).endSize + 1,
            amount: this.groups.at(-1).amount
          }
        )
      }   
    }
    this.groups.push({amount: 1, endSize: null})
    this.requestUpdate();
  }

  removeGroup() {
    if (this.groups.length < 2) return;
    this.groups.pop();
    this.groups[this.groups.length - 1].endSize = null
    this.requestUpdate();
  }

  handleDataUpdate(e) {
    let str = e.target.value;
    this.data = str.split(/[,;:\s]+/).map((val) => parseInt(val)).filter((elt) => typeof(elt) === 'number' && !isNaN(elt)).sort((a,b) => a - b)
  }

  render() {
    return html`
      <div id="container">
        <div id="controls">
          <sl-card class="input-card">
            <div slot="header">Budget Groups</div>
            ${ this.groups.map((group, idx) => this.budgetCardTemplate(group, idx)) }
            <sl-button variant="success" pill @click=${this.addGroup}>＋ Add Budget Group</sl-button>
            <sl-button variant="danger" style="margin-top: 8px;" pill @click=${this.removeGroup}>－ Remove Last Budget Group</sl-button>
          </sl-card>
        </div>
        <div id="graph-container">
          <sl-card class="input-card">
            <div slot="header">Calculator Data</div>
            <sl-textarea placeholder="Add list of case loads" value=${this.data.sort((a,b) => a - b).toString()} @sl-change=${this.handleDataUpdate}></sl-textarea>
            <sl-divider></sl-divider>
            <label>Or upload data file:
              <input class="file-input" type="file" name="csvFile" accept=".csv" @change="${this.#processFile}"></input> 
            </label>
          </sl-card>
           <sl-card> 
              <p>Total # Teachers: ${this.data.length}</p>
              <p>Total # Cases: ${this.data.reduce((acc, val) => (val + acc), 0)}</p>
              <p>
                Total Required Budget: $${
                  this.groupData().reduce(
                    (acc,group) => (acc + (group.data.reduce((acc, val) => (acc + val),0) * group.amount)),
                    0
                  )
                }
              </p>
          </sl-card>
          <canvas ${ref(this.chartRef)} ></canvas>
        </div>
      </div>
    `;
  }
}

// Try adding `<%= lit :happy_days, hello: "there" %>` somewhere in an ERB template
// on your site to see this example Lit component in action!
customElements.define("graph-component", GraphComponentElement)
